var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card bg-primary"},[_c('div',{staticClass:"card-body"},[_c('seller-prize',{attrs:{"name":_vm.sellers[0].name,"imgUrl":_vm.sellers[0].imagUrl,"isDark":true,"data":[
            {
              label: 'Poeng',
              value: Math.round(_vm.sellers[0].monthPoints * 100) / 100,
            },
            {
              label: 'Salg',
              value: _vm.sellers[0].monthSales,
            } ]}})],1)])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card bg-light"},[_c('div',{staticClass:"card-body"},[_c('seller-prize',{attrs:{"name":_vm.sellers[1].name,"imgUrl":_vm.sellers[1].imagUrl,"data":[
            {
              label: 'Poeng',
              value:  Math.round(_vm.sellers[1].monthPoints * 100) / 100,
            },
            {
              label: 'Salg',
              value: _vm.sellers[1].monthSales,
            } ]}})],1)])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card bg-white"},[_c('div',{staticClass:"card-body"},[_c('seller-prize',{attrs:{"name":_vm.sellers[2].name,"imgUrl":_vm.sellers[2].imagUrl,"data":[
          {
            label: 'Poeng',
            value:  Math.round(_vm.sellers[2].monthPoints * 100) / 100,
          },
          {
            label: 'Salg',
            value: _vm.sellers[2].monthSales,
          } ]}})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"card text-white bg-primary"},[_c('kit-general-12v1',{attrs:{"title":"Salg i dag","reached":_vm.todayKPI.nsales,"budget":_vm.todayKPI.budget}})],1)]),_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"card text-white bg-primary"},[_c('kit-general-12v1',{attrs:{"title":"Salg denne måneden","reached":_vm.monthKPI.nsales,"budget":_vm.monthKPI.budget}})],1)])])]),_c('div',{staticClass:"col-lg-4"},[_c('a-table',{staticStyle:{"background":"#ffffff"},attrs:{"data-source":_vm.sellers.filter(function (x,i) { return i>2; }),"columns":_vm.columns,"pagination":false}})],1)]),_vm._m(1)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cui__utils__heading",staticStyle:{"color":"#fff"}},[_c('strong',[_vm._v("Talkmore Privat")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cui__utils__heading"},[_c('strong',[_vm._v("Salg / Budsjett")])])}]

export { render, staticRenderFns }