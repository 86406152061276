<template>
  <div class="py-4 text-center">
    <div class="kit__utils__avatar kit__utils__avatar--rounded kit__utils__avatar--size84 border border-5 border-white d-inline-block mb-3">
      <img :src="imgUrl" :alt="name" v-if="imgUrl"/>
      <img src="/resources/images/avatars/avatar-2.png" alt="Avatar" v-else/>
    </div>
    <div class="font-weight-bold font-size-24 mb-4" :class="{ 'text-dark': !isDark, 'text-white': isDark }">{{ name }}</div>
    <div class="font-size-18 mt-n1" :class="{ 'text-gray-6': !isDark, 'text-white': isDark }" v-for="(el, i) in data" :key="i">
      {{ el.label }}: {{ el.value }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral23',
  props: ['data', 'name', 'imgUrl', 'isDark'],
}
</script>
