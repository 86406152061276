<template>
  <div class="card-body">
    <div class="d-flex mb-1">
      <div class="text-uppercase font-weight-bold mr-auto">
        {{ title }}
      </div>
      <div>
        {{ percentage }}% av budsjett oppnådd
      </div>
    </div>
    <div class="d-flex mb-2">
      <div class="font-size-24 font-weight-bold mr-auto">{{ reached }}</div>
      <div class="font-size-24">{{ budget }}</div>
    </div>
    <div class="progress">
      <div
        class="progress-bar bg-success"
        :style="{width: percentage + '%' }"
        role="progressbar"
        :aria-valuenow="percentage"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral12v1',
  props: ['title', 'reached', 'budget'],
  data() {
    return {
      percentage: 0,
    }
  },
  watch: {
    reached: {
      immediate: true,
      handler () {
        this.percentage = Math.round((this.reached / this.budget) * 100)
      },
    },
  },
}
</script>
