<template>
  <div>
    <div v-for="(view, i) in views" :key="i" :class="{'hide-el': time !== i}">
      <iframe :src="view.url" frameborder="0" class="iframetv2" @load="iframescroll" v-if="view.type === 'iframe'"></iframe>
      <top-seller :department="view.department" v-if="view.type === 'tmb2c'"/>
      <div class="timercounter">
        {{ timer }}
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import TopSeller from '@/components/custom/reports/tmb2c/topseller'

export default {
  components: {
    TopSeller,
  },
  data() {
    return {
      views: [],
      time: 0,
      settings: {},
      timer: 1,
      intervalId: null,
    }
  },
  async mounted() {
    this.getViews().then(() => {
      this.setupShiftView()
    })
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  watch: {
    views: {
      handler() {
        this.shiftView2()
      },
      deep: true,
    },
  },
  methods: {
    iframescroll() {
    },
    setupShiftView() {
      // Clear existing interval
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      // Set up a new interval
      this.intervalId = setInterval(() => {
        this.timer -= 1
        if (this.timer <= 0) {
          this.time += 1
          this.timer = this.settings.length
          if (this.time >= this.views.length) {
            this.time = 0
          }
        }
      }, 1000)
    },
    async getViews() {
      const ref = firestore.collection('TV').doc(this.$route.params.id)
      ref.onSnapshot(doc => {
        const tv = doc.data()
        this.settings = {
          length: Number(tv.length),
        }
        this.views = []
        this.timer = this.settings.length
        tv.views.forEach(view => {
          this.views.push(view)
        })
        console.log(this.views)
      })
    },
  },
}
</script>

<style>
.iframetv2 {
  width: 100%;
  height: 100%;
}
.timercounter {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  z-index: 999;
}
</style>
