<template>
  <div v-if="loaded">
    <div class="cui__utils__heading" style="color: #fff">
      <strong>Talkmore Privat</strong>
      <!-- <a-range-picker @change="onChange"  style="float: right;" :value="dateRange" /> -->
    </div>
    <div class="row">
    <div class="col-lg-8">
      <div class="row">
        <div class="col-lg-4">
          <div class="card bg-primary">
            <div class="card-body">
              <seller-prize
              :name="sellers[0].name"
              :imgUrl="sellers[0].imagUrl"
              :isDark="true"
              :data="[
              {
                label: 'Poeng',
                value: Math.round(sellers[0].monthPoints * 100) / 100,
              },
              {
                label: 'Salg',
                value: sellers[0].monthSales,
              },
              ]"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card bg-light">
            <div class="card-body">
              <seller-prize
              :name="sellers[1].name"
              :imgUrl="sellers[1].imagUrl"
              :data="[
              {
                label: 'Poeng',
                value:  Math.round(sellers[1].monthPoints * 100) / 100,
              },
              {
                label: 'Salg',
                value: sellers[1].monthSales,
              },
              ]"
              />
            </div>
          </div>
        </div>
      <div class="col-lg-4">
        <div class="card bg-white">
          <div class="card-body">
            <seller-prize
            :name="sellers[2].name"
            :imgUrl="sellers[2].imagUrl"
            :data="[
            {
              label: 'Poeng',
              value:  Math.round(sellers[2].monthPoints * 100) / 100,
            },
            {
              label: 'Salg',
              value: sellers[2].monthSales,
            },
            ]"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4">
        <div class="card text-white bg-primary">
          <kit-general-12v1 title="Salg i dag" :reached="todayKPI.nsales" :budget="todayKPI.budget"/>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card text-white bg-primary">
          <kit-general-12v1 title="Salg denne måneden" :reached="monthKPI.nsales" :budget="monthKPI.budget"/>
        </div>
      </div>
    </div>
    </div>
      <div class="col-lg-4">
        <a-table :data-source="sellers.filter((x,i) => i>2)" :columns="columns" style="background: #ffffff" :pagination="false"/>
      </div>
    </div>
    <div class="cui__utils__heading">
      <strong>Salg / Budsjett</strong>
      <!-- <a-range-picker @change="onChange"  style="float: right;" :value="dateRange" /> -->
    </div>
  </div>
</template>

<script>
import KitGeneral12v1 from '@/components/kit/widgets/General/12v1'
import SellerPrize from '@/components/custom/visual/sellerprize'

import { firestore } from '@/services/firebase/'
import moment from 'moment'

const tableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: 'Office',
    dataIndex: 'office',
    key: 'office',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Salary',
    dataIndex: 'salary',
    key: 'salary',
    sorter: (a, b) => a.salary - b.salary,
  },
]

export default {
  components: {
    KitGeneral12v1,
    SellerPrize,
  },

  data() {
    return {
      department: 'all',
      tableColumns,
      dateRange: [
        moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'YYYY/MM/DD'),
        moment(new Date(), 'YYYY/MM/DD'),
      ],
      sales: [],
      todayKPI: {},
      monthKPI: {},
      loaded: false,
      variables: {},
      sellers: [],
      columns: [
        {
          title: 'Plass',
          dataIndex: 'key',
          key: 'position',
        },
        {
          title: 'Selger',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Salg',
          dataIndex: 'monthSales',
          key: 'monthSales',
        },
        {
          title: 'Poeng',
          dataIndex: 'monthPoints',
          key: 'monthPoints',
        },
      ],
    }
  },
  methods: {
    moment,
    async getData() {
      const start = new Date(this.dateRange[0])
      const end = new Date(this.dateRange[1])
      let sellers = []
      await firestore.collection('variables').doc('tmb2c').get()
        .then(doc => {
          this.variables = doc.data()
          return firestore.collection('users').where('status', '==', 'active').orderBy('fullName').orderBy('talkmoreB2C').get()
        }).then(snapshot => {
          snapshot.forEach(doc => {
            const seller = {
              id: doc.data().employeeID,
              name: doc.data().fullName,
              department: doc.data().department,
            }
            sellers.push(seller)
          })
          return firestore.collection('tmb2c-sales').where('dateCreated', '>', start).where('dateCreated', '<', end).orderBy('dateCreated', 'asc').get()
        }).then(snapshot => {
          this.sales = []
          const today = moment().format('YYYY-MM-DD')
          snapshot.forEach(doc => {
            const sale = doc.data()
            sale.id = doc.id
            sale.dateCreated = moment(doc.data().dateCreated.toDate()).format('YYYY-MM-DD')
            this.sales.push(sale)
          })
          console.log(sellers)
          sellers.forEach((seller, i) => {
            sellers[i].monthSales = 0
            sellers[i].monthPoints = 0
          })
          const todayKPI = {
            nsales: 0,
            budget: this.variables.budget.day,
          }
          const weekKPI = {
            nsales: 0,
            budget: this.variables.budget.week,
          }
          const monthKPI = {
            nsales: 0,
            budget: this.variables.budget.month,
          }
          this.sales.forEach(sale => {
            const i = sellers.findIndex(x => x.id === sale.seller.id)
            if (i >= 0) {
              if (this.department === 'all' | sellers[i].department === this.department) {
                if (sale.status === '01 registered') {
                  let nSub = 1
                  if (sale.children) {
                    nSub += sale.children.length
                  }
                  if (sale.dateCreated === today) {
                    todayKPI.nsales += nSub
                  }
                  if (sale.dateCreated.substring(0, 7) === today.substring(0, 7)) {
                    sellers[i].monthSales += nSub
                    sellers[i].monthPoints += sale.points
                    sellers[i].monthPoints = Math.round(sellers[i].monthPoints * 100) / 100
                    monthKPI.nsales += nSub
                  }
                  if (Number(moment(sale.dateCreated).week()) === Number(moment(today).week())) {
                    weekKPI.nsales += nSub
                  }
                }
              }
            } else {
              console.log(sale)
            }
          })
          sellers.sort((a, b) => b.monthPoints - a.monthPoints)
          sellers = sellers.filter((value, index, self) =>
            index === self.findIndex((t) => t.monthSales !== 0 && t.name === value.name),
          )
          let c = 1
          sellers.forEach((seller, i) => {
            sellers[i].key = c
            c += 1
          })
          this.sellers = sellers
          this.todayKPI = todayKPI
          this.weekKPI = weekKPI
          this.monthKPI = monthKPI
        })
      this.loaded = true
    },
  },
  mounted() {
    this.getData()
  },
}
</script>
